<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Workflow
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="workFlowEditForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Workflow Name*"
                label-for="h-work-flow-name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-work-flow-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <b-form-group
                label="Description"
                label-for="h-event-description"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  vid="description"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-description"
                    v-model="description"
                    placeholder="Description"
                    :state="(errors.length > 0 || descriptionValidation) ? false : null"
                    name="description"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--<b-col cols="9">
              <validation-provider
                #default="{ errors }"
                name="Approver(s)"
                vid="approvers"
                rules="required"
              >
                <b-form-group
                  label="Approver(s)*"
                  label-for="h-team-approvers"
                  label-cols-md="2"
                  :state="(errors.length > 0 || approversValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="approvers"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveUserName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <option
                            disabled
                            value=""
                          >
                            Choose a User....
                          </option>
                        </template>
                        <option
                          v-for="(user, ind) in userOptions"
                          :key="ind"
                          :value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="approversValidation"
                    class="text-danger"
                  >
                    {{ approversError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Next Status"
                vid="nextStatus"
                rules=""
              >
                <b-form-group
                  label="Next Status if Verified"
                  label-for="h-work-flow-nextStatus"
                  label-cols-md="3"
                  :state="(errors.length > 0 || nextStatusValidation) ? false : null"
                >
                  <v-select
                    id="h-work-flow-nextStatus"
                    v-model="nextStatus"
                    label="title"
                    placeholder="Select from list"
                    :options="nextStatusOption"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="nextStatusValidation == true ? nextStatusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nextStatusValidation"
                    class="text-danger"
                  >
                    {{ nextStatusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />-->

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-work-flow-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-work-flow-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                vid="category"
                rules=""
              >
                <b-form-group
                  label="Category"
                  label-for="h-work-flow-category"
                  label-cols-md="3"
                  :state="(errors.length > 0 || categoryValidation) ? false : null"
                >
                  <v-select
                    id="h-work-flow-category"
                    v-model="category"
                    label="name"
                    placeholder="Select Category"
                    :options="categoryOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="categoryValidation == true ? categoryValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="categoryValidation"
                    class="text-danger"
                  >
                    {{ categoryError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Approval Required for Frontend Booking?"
                label-for="h-activity-type-approvalRequired"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="approvalRequired"
                  vid="approvalRequired"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-activity-type-approvalRequired"
                    v-model="approvalRequired"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(selection, sIndex) in approvalSelections"
                      :key="sIndex"
                      :value="selection.value"
                    >
                      <feather-icon
                        v-if="approvalRequired === selection.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ selection.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="approvalRequiredInvalid"
                    class="text-danger"
                  >
                    {{ approvalRequiredInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
            <b-col cols="md-6">
              <b-form-group
                label="Show in Portal?"
                label-for="h-activity-type-customerBookable"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="customerBookable"
                  vid="customerBookable"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-activity-type-customerBookable"
                    v-model="customerBookable"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(selection, sIndex) in approvalSelections"
                      :key="sIndex"
                      :value="selection.value"
                    >
                      <feather-icon
                        v-if="customerBookable === selection.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ selection.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerBookableInvalid"
                    class="text-danger"
                  >
                    {{ customerBookableInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-workflows-index', params: { type: 'all-work-flows' } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BFormTextarea, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  // eslint-disable-next-line import/named
  required, maxValue, minValue, numeric, between,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      savedData: false,
      name: '',
      nextStatus: '',
      description: '',
      category: '',
      approvers: [],
      status: 'active',
      customerBookable: 'yes',
      approvalRequired: 'yes',
      nameError: 'Valid name is required',
      nameValidation: false,
      customerBookableInvalid: false,
      customerBookableInvalidMessage: 'Valid Approval Required is required',
      approvalRequiredInvalid: false,
      approvalRequiredInvalidMessage: 'Valid Approval Required is required',
      categoryError: 'Valid category is required',
      categoryValidation: false,
      nextStatusError: 'Valid status is required',
      nextStatusValidation: false,
      descriptionError: 'Valid operating hours is required',
      descriptionValidation: false,
      approversError: 'Valid applicable days is required',
      approversValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Archived', code: 'archived' }],
      nextStatusOption: [{ title: 'Go Somewhere', code: 'go somewhere' }, { title: 'Do Nothing', code: 'do nothing' }],
      userOptions: [],
      categoryOptions: [],
      approvalSelections: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],

      // validation rules
      required,
      numeric,
      maxValue,
      minValue,
      between,
    }
  },
  beforeMount() {
    this.$http.get('operation/work-flows/respond-with/user-options')
      .then(response => {
        this.userOptions = response.data.users ?? []
        this.categoryOptions = response.data.categoryOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get(`operation/work-flows/${this.$route.params.id}`)
      .then(response => {
        this.status = response.data.status ?? ''
        this.name = response.data.name ?? ''
        this.description = response.data.description ?? ''
        this.nextStatus = response.data.nextStatus ?? ''
        this.category = response.data.category ?? ''
        this.customerBookable = response.data.customerBookable ? 'yes' : 'no'
        this.approvalRequired = response.data.approvalRequired ? 'yes' : 'no'
        this.approvers = response.data.approvers ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    resolveUserName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }
      return ''
    },
    submitForm() {
      this.$refs.workFlowEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('nextStatus', this.nextStatus)
          formData.append('description', this.description)
          formData.append('category', this.category)
          formData.append('customerBookable', this.customerBookable)
          formData.append('approvalRequired', this.approvalRequired)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.approvers.length; i++) {
            formData.append('approvers[]', this.approvers[i])
          }
          formData.append('status', this.status)

          this.$http.patch(`operation/work-flows/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Workflow Updated.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    this.savedData = true
                    if (result.value) {
                      this.$router.push({ name: 'operation-workflows-index', params: { type: 'all-work-flows' } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'nextStatus') {
                    this.nextStatusError = validationError.msg
                    this.nextStatusValidation = true
                  } else if (validationError.param === 'description') {
                    this.descriptionError = validationError.msg
                    this.descriptionValidation = true
                  } else if (validationError.param === 'approvers') {
                    this.approversError = validationError.msg
                    this.approversValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
